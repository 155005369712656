import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Labor Day`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Today is our free Labor Day workout at 10:30 at CrossFit The Ville
so invite a friend!  All other classes and open gym is cancelled for the
day.`}</em></strong></p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`5-Tire Flips`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`20-Pushups`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`30-Situps`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`40-Squats`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`1 Block Run (480 Meters)`}</p>
    <p><em parentName="p">{`or…Advanced WOD…`}</em></p>
    <p>{`2 Rounds for time of:`}</p>
    <p>{`5-Tire Flips`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`10-Muscle Ups`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`15-GHD Situps`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`20-Pistols (total)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`1 Block Run (480 Meters)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come. Truegritcomp.wordpress.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      